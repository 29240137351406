
.eCommerce-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.sort-num {
    display: inline-block;
    background: #e7f6ef;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    line-height: 24px;
    text-align: center;
}
.sort-img {
    display: inline-block;
    img {
        display: block;
    }
}
.goods-info {
    display: flex;
    align-items: center;
    .good-img {
        width: 80px;
        height: 80px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            max-width: 100%;
        }
    }
    .info-right {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        cursor: pointer;
    }
}
